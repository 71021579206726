import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { ICMSWidgetType } from "@vfit/business/data-access";
import { handleEnv } from "@vfit/shared/providers";
var MAX_NB_RETRY = 5;
var RETRY_DELAY_MS = 800;
var isDevLocal = handleEnv("NODE_ENV") === "development" || handleEnv("NODE_ENV") === "prebuild";
var authDisabledLayout = [
    "APPOINTMENT"
];
export default function fetchRetry(input, init) {
    return _fetchRetry.apply(this, arguments);
};
function _fetchRetry() {
    _fetchRetry = _async_to_generator(regeneratorRuntime.mark(function _callee(input, init) {
        var retryLeft;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    retryLeft = MAX_NB_RETRY;
                case 1:
                    if (!(retryLeft > 0)) {
                        _ctx.next = 18;
                        break;
                    }
                    _ctx.prev = 2;
                    _ctx.next = 5;
                    return fetch(input, init);
                case 5:
                    return _ctx.abrupt("return", _ctx.sent);
                case 8:
                    _ctx.prev = 8;
                    _ctx.t0 = _ctx["catch"](2);
                    console.log("Retry:".concat(input));
                    _ctx.next = 13;
                    return delay(RETRY_DELAY_MS);
                case 13:
                    _ctx.prev = 13;
                    retryLeft -= 1;
                    return _ctx.finish(13);
                case 16:
                    _ctx.next = 1;
                    break;
                case 18:
                    throw new Error("Too many retries ".concat(input));
                case 19:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                2,
                8,
                13,
                16
            ]
        ]);
    }));
    return _fetchRetry.apply(this, arguments);
}
export var delay = function(time) {
    return new Promise(function(resolve) {
        return setTimeout(resolve, time);
    });
};
var organizeCMSPages = function(cmsPages) {
    var ref;
    var pagesToReturn = [];
    var cmsPagesLength = (ref = Object.keys(cmsPages)) === null || ref === void 0 ? void 0 : ref.length;
    if (cmsPagesLength <= 0) {
        return pagesToReturn;
    }
    Object.keys(cmsPages).forEach(function(cmsPageKey) {
        var pagesparams = cmsPages[cmsPageKey].pagesparams;
        var ref = getPageInformation(cmsPages[cmsPageKey]), layout = ref.layout, pageType = ref.pageType;
        if (pagesparams.key) {
            var ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
            var pageToAdd = {
                params: _object_spread_props(_object_spread({
                    isVBIPages: true
                }, pageType && {
                    pageType: pageType
                }), {
                    authDisabled: authDisabledLayout.includes(layout),
                    key: pagesparams.key,
                    layout: layout,
                    haveHeader: ((ref1 = pagesparams.visibility) === null || ref1 === void 0 ? void 0 : ref1.haveHeader) === "true",
                    isHeaderSmall: ((ref2 = pagesparams.visibility) === null || ref2 === void 0 ? void 0 : ref2.isHeaderSmall) === "true",
                    haveFooter: ((ref3 = pagesparams.visibility) === null || ref3 === void 0 ? void 0 : ref3.haveFooter) === "true",
                    isShow: ((ref4 = pagesparams.visibility) === null || ref4 === void 0 ? void 0 : ref4.showPages) === "true",
                    isDisabledInitialTrack: ((ref5 = pagesparams.visibility) === null || ref5 === void 0 ? void 0 : ref5.isDisabledInitialTrack) === "true",
                    isProtected: ((ref6 = pagesparams.visibility) === null || ref6 === void 0 ? void 0 : ref6.isProtected) === "true",
                    protectedPass: ((ref7 = pagesparams.visibility) === null || ref7 === void 0 ? void 0 : ref7.protectedPass) || "",
                    path: ((ref8 = pagesparams.urlBuilder) === null || ref8 === void 0 ? void 0 : ref8.map(function(urlBuilder) {
                        var ref, ref1;
                        return ((ref1 = (ref = urlBuilder.page) === null || ref === void 0 ? void 0 : ref.trim()) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) || "";
                    })) || [
                        ""
                    ],
                    elements: _object_spread({}, cmsPages[cmsPageKey])
                })
            };
            pagesToReturn.push(pageToAdd);
        }
    });
    return pagesToReturn;
};
var getPageInformation = function(page) {
    // check if is HUB page
    var hubProduct = Object.values(page).find(function(widget) {
        return widget.type === ICMSWidgetType.SLIDER_PRODUCTS_HUB_BUSINESS;
    }) || null;
    if (hubProduct) {
        var ref, ref1;
        return {
            layout: "HUB",
            pageType: hubProduct === null || hubProduct === void 0 ? void 0 : (ref = hubProduct.elements) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.productType
        };
    }
    // check if is PDP page
    var productWidget = Object.values(page).find(function(widget) {
        return widget.type === ICMSWidgetType.PRODUCT_HERO_BANNER || widget.type === ICMSWidgetType.DS_HERO_BANNER;
    }) || null;
    if (productWidget) {
        var ref2;
        return {
            layout: "PDP",
            pageType: productWidget === null || productWidget === void 0 ? void 0 : (ref2 = productWidget.product) === null || ref2 === void 0 ? void 0 : ref2.productType
        };
    }
    // check if is Secondary HUB page
    var secondaryHubWidget = Object.values(page).find(function(widget) {
        return widget.type === ICMSWidgetType.HUB_DS;
    }) || null;
    if (secondaryHubWidget) {
        return {
            layout: "SECONDARY_HUB",
            pageType: secondaryHubWidget === null || secondaryHubWidget === void 0 ? void 0 : secondaryHubWidget.productType
        };
    }
    // check if is Appointment page
    var calendarWidget = Object.values(page).find(function(widget) {
        return widget.type === ICMSWidgetType.SLOT_CALENDAR;
    }) || null;
    if (calendarWidget) {
        return {
            layout: "APPOINTMENT"
        };
    }
    // check if is Sim Activation or Serial Confirmation page
    var simActivationWidget = Object.values(page).find(function(widget) {
        return widget.type === ICMSWidgetType.SIM_ACTIVATION_OTP || widget.type === ICMSWidgetType.SERIAL_CONFIRMATION_OTP;
    }) || null;
    if (simActivationWidget) {
        return {
            layout: "SIM_ACTIVATION"
        };
    }
    // default case
    return _object_spread({
        layout: "BLANK"
    }, getPageType(page === null || page === void 0 ? void 0 : page.pagetype));
};
var getPageType = function(types) {
    if ((types === null || types === void 0 ? void 0 : types.isShoppingCart) === "true") return {
        pageType: "FIXED"
    }; // add for shpping cart fwa page
    return {};
};
export { isDevLocal, organizeCMSPages };
