import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _class_call_check from "@swc/helpers/src/_class_call_check.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import ky from "ky";
import { LoggerInstance } from "@vfit/shared/providers";
export var HttpClient = function HttpClient(defaultOptions) {
    "use strict";
    _class_call_check(this, HttpClient);
    var _this = this;
    this.get = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, options) {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.prev = 0;
                        LoggerInstance.debug("API chiamata ".concat(endpoint));
                        _ctx.next = 4;
                        return _this._client.get(endpoint, _object_spread({}, options)).json();
                    case 4:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 7:
                        _ctx.prev = 7;
                        _ctx.t0 = _ctx["catch"](0);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 11:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    0,
                    7
                ]
            ]);
        }));
        return function(endpoint, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this1 = this;
    this.post = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload, options) {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.prev = 0;
                        _ctx.next = 3;
                        return _this1._client.post(endpoint, _object_spread({}, options, payload && {
                            json: payload
                        })).json();
                    case 3:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 6:
                        _ctx.prev = 6;
                        _ctx.t0 = _ctx["catch"](0);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 10:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    0,
                    6
                ]
            ]);
        }));
        return function(endpoint, payload, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this2 = this;
    this.put = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload, options) {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.prev = 0;
                        _ctx.next = 3;
                        return _this2._client.put(endpoint, _object_spread({}, options, payload && {
                            json: payload
                        })).json();
                    case 3:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 6:
                        _ctx.prev = 6;
                        _ctx.t0 = _ctx["catch"](0);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 10:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    0,
                    6
                ]
            ]);
        }));
        return function(endpoint, payload, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this3 = this;
    this.delete = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload, options) {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.prev = 0;
                        _ctx.next = 3;
                        return _this3._client.delete(endpoint, _object_spread({}, options, payload && {
                            json: payload
                        })).json();
                    case 3:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 6:
                        _ctx.prev = 6;
                        _ctx.t0 = _ctx["catch"](0);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 10:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    0,
                    6
                ]
            ]);
        }));
        return function(endpoint, payload, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this4 = this;
    this.head = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, options) {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.prev = 0;
                        _ctx.next = 3;
                        return _this4._client.head(endpoint, _object_spread({}, options)).json();
                    case 3:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 6:
                        _ctx.prev = 6;
                        _ctx.t0 = _ctx["catch"](0);
                        throw new Error("error, /todoImplement error manager: ".concat(_ctx.t0));
                    case 9:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    0,
                    6
                ]
            ]);
        }));
        return function(endpoint, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this5 = this;
    this.patch = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload, options) {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.prev = 0;
                        _ctx.next = 3;
                        return _this5._client.patch(endpoint, _object_spread({}, options, payload && {
                            json: payload
                        })).json();
                    case 3:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 6:
                        _ctx.prev = 6;
                        _ctx.t0 = _ctx["catch"](0);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 10:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    0,
                    6
                ]
            ]);
        }));
        return function(endpoint, payload, options) {
            return _ref.apply(this, arguments);
        };
    }();
    this._client = ky.create(defaultOptions);
};
