var organizeHeader = function(header) {
    return {
        head: header === null || header === void 0 ? void 0 : header.head,
        body: header === null || header === void 0 ? void 0 : header.body
    };
};
export var useHeader = function(cmsJson) {
    var ref;
    var header = organizeHeader(cmsJson.header);
    var tagging = cmsJson === null || cmsJson === void 0 ? void 0 : (ref = cmsJson.tagging) === null || ref === void 0 ? void 0 : ref.parameter;
    return {
        header: header,
        tagging: tagging
    };
};
