import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { dxlClientWithOutJwt } from "@vfit/business/providers";
import { API } from "@vfit/shared/data-access";
/**
 * @description SSO login, authenticates a user and returns cookies. It also saves the response cookies
 * @param {ICBSSOPayload} auth provide username and password
 * @returns  {Promise<IDXLAnonymousLoginResponse>}
 */ export var dxlCBSSOLogin = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(auth) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return dxlClientWithOutJwt.post(API.DXL_CB_SSO_LOGIN, auth);
                case 2:
                    return _ctx.abrupt("return", _ctx.sent);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function dxlCBSSOLogin(auth) {
        return _ref.apply(this, arguments);
    };
}();
