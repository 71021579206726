import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useContext } from "react";
import { ErrorAction, ErrorSeverity, PageLoadingContext, errorManager } from "@vfit/business/providers";
import { useMutation, useQueryClient } from "react-query";
import { PAGES } from "@vfit/shared/data-access";
import { OtpCustomDxlErrorMessage, SendOtpErrorMessage } from "./awsHooks.models";
import { postSendConfirmationOtp } from "../../api/sendConfirmationOtp";
var useSendConfirmationOtp = function(setHandleOtpIsLoading, setErrorDetail) {
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var queryClient = useQueryClient();
    var ref = useMutation("awsSendConfirmationOtp", function(payload) {
        setHandleOtpIsLoading(true);
        return postSendConfirmationOtp(payload);
    }, {
        onSuccess: function(res) {
            queryClient.setQueryData("msisdn", res.msisdn);
            setHandleOtpIsLoading(false);
            setIsLoading(false);
        },
        onError: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(error) {
                var resp, foundMessage, otpCustomDxlMessage, errorResp;
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            _ctx.next = 2;
                            return error.response.json().catch(function() {
                                return {
                                    code: "generic-error",
                                    message: "generic-error"
                                };
                            });
                        case 2:
                            resp = _ctx.sent;
                            foundMessage = Object.values(SendOtpErrorMessage).find(function(message) {
                                var ref;
                                return (ref = resp.message) === null || ref === void 0 ? void 0 : ref.includes(message);
                            });
                            otpCustomDxlMessage = Object.values(OtpCustomDxlErrorMessage).find(function(message) {
                                var ref;
                                return (ref = resp.message) === null || ref === void 0 ? void 0 : ref.includes(message);
                            });
                            if (!foundMessage && !otpCustomDxlMessage) {
                                errorManager.handleError(ErrorSeverity.HIGH, {
                                    errorAction: ErrorAction.PAGE,
                                    errorPage: PAGES.GENERIC_ERROR
                                });
                            } else {
                                errorResp = _object_spread_props(_object_spread({}, resp), {
                                    message: foundMessage || otpCustomDxlMessage
                                });
                                setErrorDetail(errorResp);
                                setHandleOtpIsLoading(false);
                                setIsLoading(false);
                            }
                        case 6:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(error) {
                return _ref.apply(this, arguments);
            };
        }()
    }), data = ref.data, mutate = ref.mutate;
    return {
        sendResendConfirmationOtp: function(payload) {
            return mutate(payload);
        },
        data: data
    };
};
export { useSendConfirmationOtp };
