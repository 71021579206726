import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { dxlClientWithOutJwt } from "@vfit/business/providers";
import { API } from "@vfit/shared/data-access";
/**
 * @description keepAlive
 * @returns  {Promise<IKeepAliveResponse>}
 */ export var keepAlive = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return dxlClientWithOutJwt.get(API.KEEP_ALIVE, {
                        prefixUrl: "https://www.vodafone.it/",
                        credentials: "include"
                    });
                case 2:
                    return _ctx.abrupt("return", _ctx.sent);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function keepAlive() {
        return _ref.apply(this, arguments);
    };
}();
