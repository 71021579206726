import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
var organizeFooter = function(footer) {
    var ref;
    return {
        app: footer === null || footer === void 0 ? void 0 : footer.app,
        footerLinks: footer === null || footer === void 0 ? void 0 : footer.footerLinks,
        copyright: footer === null || footer === void 0 ? void 0 : footer.copyright,
        socials: _object_spread_props(_object_spread({}, footer === null || footer === void 0 ? void 0 : footer.socials), {
            elements: footer === null || footer === void 0 ? void 0 : (ref = footer.socials) === null || ref === void 0 ? void 0 : ref.elements
        }),
        mobileLinks: footer === null || footer === void 0 ? void 0 : footer.mobileLinks
    };
};
export var useFooter = function(cmsJson) {
    var footer = organizeFooter(cmsJson.footer);
    return {
        footer: footer
    };
};
