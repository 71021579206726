export var SendOtpErrorMessage;
(function(SendOtpErrorMessage) {
    SendOtpErrorMessage["MAX_TOKEN_4PERIOD_EC"] = "MAX_TOKEN_4PERIOD_EC";
    SendOtpErrorMessage["MAX_SMS_4DAY_EC"] = "MAX_SMS_4DAY_EC";
    SendOtpErrorMessage["MAX_NUM_OTT_VAL_ATT_OVER"] = "MAX_NUM_OTT_VAL_ATT_OVER";
    SendOtpErrorMessage["EXP_OTT"] = "EXP_OTT";
    SendOtpErrorMessage["OTT_ALR_CONF"] = "OTT_ALR_CONF";
    SendOtpErrorMessage["NO_OTT_REQ_FND"] = "NO_OTT_REQ_FND";
    SendOtpErrorMessage["REQUEST_IS_NOT_VALID"] = "REQUEST IS NOT VALID";
})(SendOtpErrorMessage || (SendOtpErrorMessage = {}));
export var VerifyRetrieveDataErrorMessage;
(function(VerifyRetrieveDataErrorMessage) {
    VerifyRetrieveDataErrorMessage["MAX_NUM_OTT_VAL_ATT_OVER"] = "MAX_NUM_OTT_VAL_ATT_OVER";
    VerifyRetrieveDataErrorMessage["EXP_OTT"] = "EXP_OTT";
    VerifyRetrieveDataErrorMessage["OTT_ALR_CONF"] = "OTT_ALR_CONF";
    VerifyRetrieveDataErrorMessage["INC_OTT"] = "INC_OTT";
    VerifyRetrieveDataErrorMessage["REQUEST_IS_NOT_VALID"] = "REQUEST IS NOT VALID";
})(VerifyRetrieveDataErrorMessage || (VerifyRetrieveDataErrorMessage = {}));
export var OtpCustomDxlErrorMessage;
(function(OtpCustomDxlErrorMessage) {
    OtpCustomDxlErrorMessage["CONFIRMATION_ALREADY_DONE"] = "CONFIRMATION ALREADY DONE";
    OtpCustomDxlErrorMessage["CHECK_CONTEXT_FAILED"] = "CHECK CONTEXT FAILED";
})(OtpCustomDxlErrorMessage || (OtpCustomDxlErrorMessage = {}));
