import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useContext } from "react";
import { AuthenticationContext, EAuthType, useHandleQueryWithAuthSSO } from "../../../../../providers/src";
import { dxlGetProfile } from "../../api";
var useGetProfile = function() {
    var authType = useContext(AuthenticationContext).authType;
    var getProfileQuery = {
        queryKey: "CBProfile",
        queryFn: _async_to_generator(regeneratorRuntime.mark(function _callee() {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        return _ctx.abrupt("return", dxlGetProfile());
                    case 1:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        })),
        options: {
            enabled: authType === EAuthType.CB,
            onError: function(error) {
                console.log("auth flow: Hub Page --> getProfileQuery --> onError", error);
            }
        }
    };
    var ref = useHandleQueryWithAuthSSO(getProfileQuery), dataProfile = ref.data;
    return {
        dataProfile: dataProfile
    };
};
export { useGetProfile };
