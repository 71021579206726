import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useContext } from "react";
import { ErrorAction, ErrorSeverity, PageLoadingContext, errorManager } from "@vfit/business/providers";
import { useMutation, useQueryClient } from "react-query";
import { PAGES } from "@vfit/shared/data-access";
import { OtpCustomDxlErrorMessage } from "./awsHooks.models";
import { postEndSerialConfirmation } from "../../api/endSerialConfirmation";
var useEndSerialConfirmation = function(setHandleOtpIsLoading, setErrorDetail) {
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var queryClient = useQueryClient();
    var ref = useMutation("awsEndSerialConfirmation", function(payload) {
        setHandleOtpIsLoading(true);
        queryClient.setQueryData("outcome", payload.outcome);
        return postEndSerialConfirmation(payload);
    }, {
        onSuccess: function() {
            setHandleOtpIsLoading(false);
            setIsLoading(false);
        },
        onError: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(error) {
                var ref, resp, otpCustomDxlMessage, errorResp;
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            ;
                            _ctx.next = 3;
                            return error === null || error === void 0 ? void 0 : (ref = error.response) === null || ref === void 0 ? void 0 : ref.json().catch(function() {
                                return {
                                    code: "generic-error",
                                    message: "generic-error"
                                };
                            });
                        case 3:
                            resp = _ctx.sent;
                            otpCustomDxlMessage = Object.values(OtpCustomDxlErrorMessage).find(function(message) {
                                var ref;
                                return resp === null || resp === void 0 ? void 0 : (ref = resp.message) === null || ref === void 0 ? void 0 : ref.includes(message);
                            });
                            if (!otpCustomDxlMessage) {
                                errorManager.handleError(ErrorSeverity.HIGH, {
                                    errorAction: ErrorAction.PAGE,
                                    errorPage: PAGES.GENERIC_ERROR
                                });
                            } else {
                                errorResp = _object_spread_props(_object_spread({}, resp), {
                                    message: otpCustomDxlMessage
                                });
                                setErrorDetail(errorResp);
                                setHandleOtpIsLoading(false);
                                setIsLoading(false);
                            }
                        case 6:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(error) {
                return _ref.apply(this, arguments);
            };
        }()
    }), data = ref.data, mutate = ref.mutate;
    return {
        endSerialConfirmation: function(payload) {
            return mutate(payload);
        },
        data: data
    };
};
export { useEndSerialConfirmation };
