import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useContext } from "react";
import { useMutation } from "react-query";
import { PAGES } from "@vfit/shared/data-access";
import { ErrorAction, ErrorSeverity, PageLoadingContext, errorManager } from "@vfit/business/providers";
import { awsSchedule } from "../../api/schedule";
var useSchedule = function(isOperator, redirectToTyp) {
    var setIsLoading = useContext(PageLoadingContext).setIsLoading;
    var ref = useMutation("awsSchedule", function(payload) {
        return awsSchedule(payload);
    }, {
        onSuccess: redirectToTyp,
        onError: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(error) {
                var resp, ref, errorPage, message;
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            _ctx.next = 2;
                            return error.response.json().catch(function() {
                                return {
                                    code: "generic-error"
                                };
                            });
                        case 2:
                            resp = _ctx.sent;
                            ref = getErrorPage(resp === null || resp === void 0 ? void 0 : resp.code, isOperator), errorPage = ref.errorPage, message = ref.message;
                            errorManager.handleError(ErrorSeverity.HIGH, {
                                errorAction: ErrorAction.PAGE,
                                errorPage: errorPage,
                                message: message
                            });
                        case 5:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(error) {
                return _ref.apply(this, arguments);
            };
        }()
    }), mutate = ref.mutate, isLoading = ref.isLoading;
    if (isLoading) setIsLoading(true);
    return {
        confirmSlotTime: function(payload) {
            return mutate(payload);
        }
    };
};
var getErrorPage = function(error, isOperator) {
    var ref;
    var code = (ref = error === null || error === void 0 ? void 0 : error.split(":")) === null || ref === void 0 ? void 0 : ref.pop();
    switch(code){
        case "slot-not-available":
            return {
                errorPage: PAGES.SLOT_NOT_AVAILABLE_ERROR,
                message: "slot-not-available-error"
            };
        case "time-expired":
            return {
                errorPage: isOperator ? PAGES.TIME_EXPIRED_OPERATOR_ERROR : PAGES.TIME_EXPIRED_ERROR,
                message: "time-expired"
            };
        case "appointment-no-longer-schedulable":
            return {
                errorPage: isOperator ? PAGES.SCHEDULED_APPOINTMENT_OPERATOR_ERROR : PAGES.SCHEDULED_APPOINTMENT_ERROR,
                message: "appointment-no-longer-schedulable"
            };
        default:
            return {
                errorPage: PAGES.GENERIC_ERROR,
                message: "generic-error"
            };
    }
};
export { useSchedule };
